@import Url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&amp;subset=cyrillic,cyrillic-ext,latin-ext");

html {
  overflow-y: scroll;
}

input {
  accent-color: #ff5500;
  margin-right: 5px;
}

.girar-enter {
  transform: rotate(0deg);
}

.girar-enter-active {
  transform: rotate(-180deg);
  transition: all 250ms;
}

.girar-enter-done {
  transform: rotate(180deg);
}

.girar-exit {
  transform: rotate(-180deg);
}

.girar-exit-active {
  transform: rotate(0deg);
  transition: all 100ms;
}

.opacidad-enter {
  opacity: 0;
}

.opacidad-enter-active {
  opacity: 1;
  transition: all 250ms;
}

.opacidad-exit {
  opacity: 1;
}

.opacidad-exit-active {
  opacity: 0;
  transition: all 250ms;
}

.submenu-enter {
  opacity: 0;
  transform: scale(0.95);
}

.submenu-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 250ms;
}

.submenu-exit {
  opacity: 1;
  transform: scale(1);
}

.submenu-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: all 250ms;
}

.textoBanner {
  color: #ffffff;
  text-decoration: none;
  font-family: "Open Sans";
  font-size: calc(44 * (1536px / 1920));
  font-weight: 800;
  height: calc(200 * (1536px / 1920));
  display: flex;
  align-items: center;
  padding-left: 3.44%;
}

.textoBannerMobile {
  color: #ffffff;
  text-decoration: none;
  font-family: "Open Sans";
  font-size: calc(30 * (1536px / 1920));
  font-weight: normal;
  height: calc(200 * (1536px / 1920));
  display: flex;
  align-items: center;
  max-width: calc(100% - (250 * 1536px / 1920));
  padding-left: 3.44%;
}

a {
  text-decoration: none;
}

a:hover {
  color: #3f3f46;
}

.itemNavBar {
  color: #3f3f46;
  text-decoration: none;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  font-weight: 800;
}

.submenuProductos:hover {
  background: #61616C;
}

.pregunta {
  color: #4b4b4b;
  font-family: "Open Sans";
  font-size: calc(14 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 800;
  display: flex;
}

.respuesta {
  color: #4b4b4b;
  font-family: "Open Sans";
  font-size: calc(14 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 600;
  display: flex;
}

.tituloBottom {
  color: #4b4b4b;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(21.79 * (1536px / 1920));
  font-weight: 700;
}

.linkBottom {
  color: #4b4b4b;
  font-family: "Open Sans";
  font-size: calc(13 * (1536px / 1920));
  line-height: calc(17.7 * (1536px / 1920));
  font-weight: 700;
}

.tituloError {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(80 * (1536px / 1920));
  font-weight: 800;
}

.textoError {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(40 * (1536px / 1920));
  font-weight: 800;
}

.tituloCalculo {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 800;
}

.selectCalculo {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(21.79 * (1536px / 1920));
  font-weight: 600;
}

.botonCalculo {
  color: #ffffff;
  background-color: #ef7b10;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(24 * (1536px / 1920));
  font-weight: 600;
}

.botonCalculo:disabled {
  background-color: #ffa755;
}

.botonResultado {
  color: #ef7b10;
  background-color: #ffffff;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #ef7b10;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(24 * (1536px / 1920));
  font-weight: 600;
}

.botonResultado:hover {
  color: #ef7b10;
}

.textoResultado {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  height: calc(24.51 * (1536px / 1920));
  line-height: calc(24.51 * (1536px / 1920));
  font-weight: 600;
}

.tituloPV {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(24.51 * (1536px / 1920));
  font-weight: 600;
}

.selectPV {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.dropPV {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  font-weight: 600;
}

.selectRT {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.dropRT {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  font-weight: 600;
}

.tituloProductos {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(20 * (1536px / 1920));
  line-height: calc(25.5 * (1536px / 1920));
  font-weight: 800;
}

.nombreProducto {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(25.5 * (1536px / 1920));
  font-weight: 700;
}

.subnombreProducto {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25.5 * (1536px / 1920));
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: pre-line;
}

.tarjetaProducto {
  border:  solid #D1D1D1;
  border-width: calc(1 * (1536px / 1920));
  border-radius: calc(5 * (1536px / 1920));
  background-color: transparent;
  transition-duration: 150ms;
}

.tarjetaProducto:hover {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
  transform: scale(1.05);
  transition-duration: 150ms;
}

.tituloDetalle {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(24 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.subtituloDetalle {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.descDetalle {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(14 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
  overflow-y: scroll;
  white-space: pre-wrap;
}

.botonManual {
  color: #ef7b10;
  background-color: #ffffff;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border: calc(1 * (1536px / 1920)) solid #ef7b10;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.botonManual:hover {
  color: #ef7b10;
}

.botonComprar {
  color: #ffffff;
  background-color: #ef7b10;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.botonComprar:hover {
  color: #ffffff;
}

.botonFiltros {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(21.79 * (1536px / 1920));
  font-weight: 600;
}

.filtro {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  height: calc(22 * (1536px / 1920));
  line-height: calc(22 * (1536px / 1920));
  font-weight: 600;
}

.tituloFiltro {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(50 * (1536px / 1920));
  font-weight: 800;
}

.tituloAcceso {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 500;
}

.campoAcceso {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 600;
  border: calc(1 * (1536px / 1920)) solid #3f3f46;
  border-radius: calc(5 * (1536px / 1920));
}

.selectRegistro {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.dropRegistro {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  font-weight: 600;
}

.confirmacionRegistro {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(30 * (1536px / 1920));
  font-weight: 600;
}

.botonAcceso {
  color: #ffffff;
  background-color: #ef7b10;
  display: flex;
  height: calc(44 * (1536px / 1920));
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border: calc(1 * (1536px / 1920)) solid #ef7b10;
  border-radius: calc(5 * (1536px / 1920));
  font-family: "Open Sans";
  font-size: calc(16 * (1536px / 1920));
  line-height: calc(25 * (1536px / 1920));
  font-weight: 600;
}

.botonAcceso:hover {
  color: #ffffff;
}

.botonAcceso:disabled {
  border-color: #ffa755;
  background-color: #ffa755;
}

.textoAcceso {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 500;
}

.mensajeAcceso {
  border-radius: calc(5 * (1536px / 1920));
  padding: calc(5 * (1536px / 1920));
  text-align: center;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: calc(14 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 500;
}

.tituloVerificar {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(64 * (1536px / 1920));
  font-weight: 800;
}

.mensajeVerificar {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(32 * (1536px / 1920));
  font-weight: 800;
}

.tituloClientes {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(30 * (1536px / 1920));
  line-height: calc(40.85 * (1536px / 1920));
  font-weight: 600;
}

.nombreArchivo {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(14 * (1536px / 1920));
  line-height: calc(19.07 * (1536px / 1920));
  font-weight: 700;
  overflow-wrap: break-word;
}

.tituloPosventa {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(30 * (1536px / 1920));
  line-height: calc(40 * (1536px / 1920));
  font-weight: 500;
}

.subtituloPosventa {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(20 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 700;
}

.varPosventa {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 600;
}

.campoPosventa {
  color: #3f3f46;
  font-family: "Open Sans";
  font-size: calc(18 * (1536px / 1920));
  line-height: calc(21 * (1536px / 1920));
  font-weight: 500;
}

.scrollSinBarra {
  scroll-behavior: smooth;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.scrollSinBarra::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}